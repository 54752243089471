import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description && !title) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 18.75rem auto 12.5rem;
  padding: 0 11.944vw;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.875rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        border-radius: 50%;
        position: absolute;
        top: 0.8125rem;
        left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`
